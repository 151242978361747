@font-face {
font-family: '__atypFont_ef3633';
src: url(/_next/static/media/a9d3087643e88082-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__atypFont_ef3633';
src: url(/_next/static/media/777c300fd084f815-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}@font-face {font-family: '__atypFont_Fallback_ef3633';src: local("Arial");ascent-override: 71.89%;descent-override: 20.39%;line-gap-override: 37.84%;size-adjust: 108.36%
}.__className_ef3633 {font-family: '__atypFont_ef3633', '__atypFont_Fallback_ef3633', ui-sans-serif
}

@font-face {
font-family: '__akzidenzFont_ed8fc5';
src: url(/_next/static/media/8e128fb88ad153c3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
}

@font-face {
font-family: '__akzidenzFont_ed8fc5';
src: url(/_next/static/media/be1a85dc638fa387-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__akzidenzFont_ed8fc5';
src: url(/_next/static/media/d22955b1379b27d6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__akzidenzFont_ed8fc5';
src: url(/_next/static/media/e21f39dfc9bda088-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: '__akzidenzFont_ed8fc5';
src: url(/_next/static/media/4dcb2362a8ec65c1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: italic;
}@font-face {font-family: '__akzidenzFont_Fallback_ed8fc5';src: local("Arial");ascent-override: 105.14%;descent-override: 32.87%;line-gap-override: 0.00%;size-adjust: 94.92%
}.__className_ed8fc5 {font-family: '__akzidenzFont_ed8fc5', '__akzidenzFont_Fallback_ed8fc5'
}

